/* Custom property define karen */
:root {
  --thm-black-rgb: 49, 48, 65;
}

/* Existing styles */
.carousel-item video {
  background-color: rgb(var(--thm-black-rgb));
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  color: black;
  position: relative;
  
}
.centered-caption h1 {
  font-size: 4.4rem;
  align-items: center;
}
.carousel-caption .styled-p {
  color: #ffffff;
  font-size: 25px;
  margin: 0 ;
  display: inline-block; /* Ensure the paragraph takes only necessary width */
  position: relative;
  left: 20%; /* Start at 50% from the left of the parent container */
  transform: translateX(-50%); /* Move back by 50% of the element's width to center it */
}
/* Overlay styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

/* Centered caption styles */
.centered-caption {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  align-items: center;
  white-space: nowrap;
}

/* Responsive styles for different screen sizes */
@media (max-width: 768px) {
  .centered-caption h1 {
    font-size: 1.5rem;
  }
  .carousel-video {
    height: 70vh; /* Adjust height as needed */
    width: auto; /* Allow width to auto-adjust */
    object-fit: cover; /* Ensure video still covers the container */
  }
  .centered-caption {
    top: 45%;
    left: 30%;
    transform: translate(-50%, -50%);
  }

  .centered-caption p {
    font-size: 1rem;
  }
  .search-filter {
    position: absolute;
    top: 90%;
    left:86%;
    transform: translate(-50%, -50%);
    align-items: center;
    white-space: nowrap;
    display: flex;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 150%;
    max-width: 800px;
    margin: auto;
  }
}

@media (max-width: 576px) {
  .centered-caption h1 {
    font-size: 2.1rem;
  }
  .carousel-caption .styled-p {
    color: #ffffff;
    font-size: 15px;
    
  display: inline-block; /* Ensure the paragraph takes only necessary width */
  position: relative;
  left: 15%; /* Start at 50% from the left of the parent container */
  transform: translateX(-50%); /* Move back by 50% of the element's width to center it */
    text-align: center;
  }
  .centered-caption p {
    font-size: 0.9rem;
  }
  
}

/* New style for the desired text */
.carousel-caption .styled-h1 {
  font-family: "Pacifico", cursive;
  color: #7ca24a;
  font-size: 45px;
  font-weight: normal;
  text-align: center;
}



.carousel-caption p {
  animation: bounce 3s ease forwards;
}

.carousel-caption button {
  display: flex;
  font-family: 'Euclid Circular A';
  background-color: #530937;
  color: white;
  font-size: 1.2rem;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.carousel-caption button:hover {
  background-color: #ff8000;
  transform: scale(1.1);
}

/* Styles for removing underline from the link */
.no-underline {
  text-decoration: none;
}

@keyframes bounce {
  from {
    opacity: 0;
    transform: translateY(500px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.carousel-item {
  animation: slide-in 1s;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Responsive styles for specific devices */
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .carousel-caption .styled-h1 {
    font-size: 2.6rem;
  }
  .carousel-caption .styled-p {
    font-size: 1rem;
  }
}

@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .carousel-caption .styled-h1 {
    font-size: 2.7rem;
  }
  .carousel-caption .styled-p {
    font-size: 1rem;
  }
}

@media only screen and (device-width: 360px) and (device-height: 740px) and (-webkit-device-pixel-ratio: 4) {
  .carousel-caption .styled-h1 {
    font-size: 2.5rem;
  }
  .carousel-caption .styled-p {
    font-size: 0.95rem;
  }
}

@media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) {
  .carousel-caption .styled-h1 {
    font-size: 5rem;
  }
  .carousel-caption .styled-p {
    font-size: 1.8rem;
  }
  
}

@media only screen and (min-width: 1536px) and (max-width: 1600px) and (-webkit-device-pixel-ratio: 4) {
  .carousel-caption .styled-h1 {
    font-size: 2rem;
  }
  .carousel-caption .styled-p {
    font-size: 1.5rem;
  }
}


@media (max-width: 768px) {
  .search-filter {
    top: 110%;
    left: 87%;
    flex-direction: column;
    align-items: center;
    width: 150%; /* Full width for mobile */
    padding: px 15px; /* Balanced padding for mobile */
    background: white;
    border-radius: 5px; /* Keep a consistent border radius */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.); /* Light shadow for depth */
    gap: 15px; /* Space between elements */
  }

  .search-filter input {
    width: 100%; /* Full width for inputs */
    margin: 0; /* Remove margin */
    padding: 15px; /* Comfortable padding */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 5px; /* Slight rounding */
    font-size: 1rem; /* Consistent font size */
  }

  .search-filter button {
    width: 100%; /* Full width button */
    padding: 10px; /* Comfortable padding */
    background-color: #5191fa; /* Primary button color */
    color: white;
    border: none;
    border-radius: 5px; /* Matching rounding */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .search-filter button:hover {
    background-color: #000000; /* Darker shade on hover */
  }
}





/* Custom property define karen */

/* Existing styles */
.carousel-item img {
  background-color: rgb(var(--thm-black-rgb)); /* Custom property ka use karen */
  background-size: cover;
  background-position: center;
  height: 580px; /* Aap apni zarurat ke anusar height set kar sakte hain */
  display: flex;
  align-items: center;
  color: black;
  position: relative; /* Make the image position relative to position the overlay */
}



/* Centered caption styles */
.centered-caption {
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  align-items: center;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

/* Responsive styles for different screen sizes */
@media (max-width: 768px) {
 
  .centered-caption {
    top: 55%;
    left: 30%;
    transform: translate(-50%, -50%);
    align-items: center;
    white-space: nowrap; /* Prevent text from wrapping to the next line */
  }
  

}








