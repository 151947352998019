.search-navbar {
    padding: 10px;
    color: #fff;
    position: relative;
  }
  
  .search-container {
    display: flex;
    align-items: center;
  }
  
  .search-input {
    padding: 8px;
    font-size: 17px;
    flex: 1;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .search-button {
    padding: 8px 16px;
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .search-results {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    border-radius: 4px;
  }
  
  .no-results {
    padding: 10px;
    color: #999;
  }
  
  .result-item {
    cursor: pointer;
    padding: 10px;
    background-color: #fff;
    color: #000000;
    border-bottom: 1px solid #ccc;
  }
  
  .result-item.highlighted {
    background-color: #000000;
    color: #fff;
  }
  .search-results {
    list-style-type: none;
    padding: 0;
    margin: 10px 0 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    border-radius: 4px;
    max-height: 300px; /* Limit the height */
    overflow-y: auto; /* Enable vertical scrolling */
}

@media (max-width: 768px) {
    .search-results {
        max-height: 200px; /* Smaller height for mobile */
        overflow-y: scroll; /* Allow scrolling on mobile */
    }
}
