.hill-container {
    text-align: center;
    padding: 20px;
  }
  
  .hill-heading h6 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Grid for larger screens */
  .hill-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .hill-card {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .hill-card:hover {
    transform: scale(1.05);
  }
  
  .hill-link {
    display: block;
    text-decoration: none;
    color: inherit;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  
  .hill-link.active .hill-card-content h3 {
    color: #ffdf00; /* Yellow color on hover */
  }
  
  .hill-link.active .tour-count {
    background-color: #ff4500; /* Change button background on hover */
  }
  
  .hill-card-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  .hill-card-content {
    position: absolute;
    bottom: 10%;
    left: 10%;
    color: white;
  }
  
  .hill-card-content h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .tour-count {
    background-color: #2e00b8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
  }
  
  .hill-card:hover .hill-card-content h3 {
    color: #ffdf00; /* Change text color on hover */
  }
  
  .hill-card:hover .tour-count {
    background-color: #ff4500; /* Change button background on hover */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 1024px) {
    .hill-cards-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  
    .hill-card-content h3 {
      font-size: 1.5rem;
    }
  
    .tour-count {
      font-size: 0.9rem;
    }
  
    .hill-card-img {
      height: 250px;
    }
  }
  
  @media (max-width: 768px) {
    .hill-cards-container {
      grid-template-columns: 1fr;
    }
  
    .hill-card-content h3 {
      font-size: 1.4rem;
    }
  
    .tour-count {
      font-size: 0.85rem;
    }
  
    .hill-card-img {
      height: 200px;
    }
  }
  .hill-card {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .hill-card:hover {
    transform: scale(1.05);
  }
  
  .hill-card-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  
  /* Center content inside the image */
  .hill-card-content {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }
  
  .hill-card-content h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .tour-count {
    background-color: #2e00b8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
  }
  
  .hill-card:hover .hill-card-content h3 {
    color: #ffdf00; /* Change text color on hover */
  }
  
  .hill-card:hover .tour-count {
    background-color: #ff4500; /* Change button background on hover */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 1024px) {
    .hill-card-content h3 {
      font-size: 1.5rem;
    }
  
    .tour-count {
      font-size: 0.9rem;
    }
  
    .hill-card-img {
      height: 250px;
    }
  }
  
  @media (max-width: 768px) {
    .hill-card-content h3 {
      font-size: 1.4rem;
    }
  
    .tour-count {
      font-size: 0.85rem;
    }
  
    .hill-card-img {
      height: 200px;
    }
  }
  .hill-card {
    position: relative;
    width: 100%;
    height: 400px; /* Adjusted card height */
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .hill-card:hover {
    transform: scale(1.05);
  }
  
  .hill-card-img {
    width: 100%;
    height: 100%; /* Adjust to cover full height of the card */
    object-fit: cover;
  }
  
  /* Center content inside the image */
  .hill-card-content {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }
  
  .hill-card-content h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .tour-count {
    background-color: #2e00b8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
  }
  
  .hill-card:hover .hill-card-content h3 {
    color: #ffdf00; /* Change text color on hover */
  }
  
  .hill-card:hover .tour-count {
    background-color: #ff4500; /* Change button background on hover */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 1024px) {
    .hill-card {
      height: 350px; /* Adjusted height for tablets */
    }
  
    .hill-card-content h3 {
      font-size: 1.5rem;
    }
  
    .tour-count {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .hill-card {
      height: 300px; /* Adjusted height for mobile */
    }
  
    .hill-card-content h3 {
      font-size: 1.4rem;
    }
  
    .tour-count {
      font-size: 0.85rem;
    }
  }
  